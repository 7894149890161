import { mutation } from "./mutations";
import { TeacherApiCalls } from '../../services/teacher';

const handleError = err => {
    return err.response || err;
};

// Actions
export const getGlobalSearch = (query) => async dispatch => {
    try {
        const result = await TeacherApiCalls.getSearchResult(query);
        dispatch(mutation.setSearchResult(result));
        return result;
    } catch (err) {
        handleError(err);
    }
}

export const getAllLevels = () => async dispatch => {
    try {
        const result = await TeacherApiCalls.getLevels();
        dispatch(mutation.setLevels(result));
        return result;
    } catch (err) {
        handleError(err);
    }
}