const setProfileInfo = (data) => ({
  type: "SET_PROFILE_INFO",
  data,
});

const setAvailableSchedules = (data) => ({
  type: "SET_AVAILABLE_SCHEDULE",
  data,
});

const setStudentID = (data) => ({
  type: 'SET_STUDENT_ID',
  data,
})

const setStudentBatch = (data) => ({
  type: 'SET_STUDENT_BATCH',
  data,
})

const setStudentClasses = (data) => ({
  type: 'SET_STUDENT_CLASSES',
  data,
})

const loadingClassData = () => ({
  type: 'LOADING_CLASS_DATA'
})

const classDataLoaded = () => ({
  type: 'CLASS_DATA_LOADED'
})

const setSessionInfo = (data) => ({
  type: "SET_SESSION_DATA",
  data
})

export const mutation = {
  setStudentID,
  setStudentBatch,
  setStudentClasses,
  setProfileInfo,
  setAvailableSchedules,
  loadingClassData,
  classDataLoaded,
  setSessionInfo,
};
