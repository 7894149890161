// export const API_URL = "https://api.getfreadom.com/api/";

// const isProdEnv = () => ['web', 'staging'].includes(window.location?.hostname?.split('.')?.[0]);
const isProdEnv = () => window.location?.hostname?.split(".")?.[0];

export const API_URL =
  isProdEnv() === "web"
    ? "https://api.rotaryschool.getfreadom.com/api/"
    : isProdEnv() === "staging"
    ? "https://api.rotaryschool.getfreadom.com/api/"
    // : "http://127.0.0.1:8000/api/";
    : "https://api.rotaryschool.getfreadom.com/api/";

export const F_LIVE_API_URL =
  isProdEnv() === "web" || isProdEnv() === "staging"
    ? "https://apicmseducation.getfreadom.com/"
    : "https://apicmseducation.getfreadom.com/";
