import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { submitAnswer } from "store/story/actions";
import * as activityActions from "store/activities/actions";
import * as newsActions from "store/news/actions";
import cn from "classnames";
import "./Quiz.scss";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Row, Col } from "antd";
import { Analytics } from "../../services/analytics";
import * as EVENT from "../../utils/eventKeys";
import { storage as LocalStorage } from "services/config/storage";
class Quiz extends Component {
  state = {
    questionNo: 0,
    optionSelected: false,
    rewardData: null,
    selectedOption: {},
    showSubmit: true,
    index: 1,
    options: [],
    showCorrectOrder: false,
    is_valid_answer: false,
  };

  componentDidMount() {
    const { questions } = this.props;
    const { questionNo } = this.state;
    if (
      questions[questionNo].given_answer &&
      questions[questionNo].sub_category === "normal"
    ) {
      let questionArray = [
        "acc4b7f8-92b4-4234-8f0f-18fc0cee97d0",
        "6a99f878-9111-4b2a-97cc-823573380f81",
        "c2368250-822b-4b0c-87d4-5b380633c5be",
        "e8b08f9f-499b-44ae-8f8e-a51b0248f5bb",
        "3ba79ab4-5238-4133-875b-5e3f4396a1b4",
        "84f3589c-2da7-48c4-baf4-7375c95aa9c1",
        "aa8cc402-05ae-4367-96e1-69bf7287c332",
        "a487f31d-c340-4762-8095-7824e7fa6601",
        "82f690c0-3790-42f3-aa0f-2b73d78a73d9",
        "ee98bfd4-222b-44a3-b240-4e1242b78a16",
        "fe26ead4-ed62-4ac7-a5a4-78d37f755a3b",
        "b83b8c60-0c9a-4fd7-93a3-64c5a56c3515",
        "efdc3c49-8fe9-41b1-9d60-2066519210d9",
        "14244fc6-84fa-4286-ac6e-ff994b343589",
        "a6ee4b27-9f52-4341-9c2c-9477a983c04a",
      ];
      this.setState({
        is_valid_answer:
          questions[questionNo].given_answer ===
          questions[questionNo].valid_option
            ? true
            : questionArray.includes(questions[questionNo].id)
            ? true
            : false,
      });
      this.setState({
        optionSelected: questions[questionNo].given_answer,
        showSubmit: false,
        selectedOption: null,
      });
    }
    if (
      questions[questionNo].order_answered &&
      questions[questionNo].order_answered.length > 0
    ) {
      this.setState({
        options: questions[questionNo].order_answered,
        optionSelected: questions[questionNo].order_answered,
        showSubmit: false,
        selectedOption: null,
      });
    } else if (questions[questionNo].option) {
      this.setState({ options: questions[questionNo].option });
    }
    this.sendQuesAnalytics(EVENT.VIEW_QUES, questions[questionNo], null);
  }

  sendQuesAnalytics = (event, ques, option) => {
    let payload = {};
    if (this.props.storyData) {
      payload[EVENT.STORY_TYPE] = this.props.storyData.story_type;
      payload[EVENT.STORY_ID] = this.props.storyData.id;
    }
    if (this.props.activityData) {
      payload[EVENT.ACTIVITY_PACK_ID] = this.props.activityData.activity_pack;
      payload[EVENT.ACTIVITY_NAME] = this.props.activityData.name;
      // payload[EVENT.ACTIVITY_ID]=this.props.activityData.activity
    }
    if (this.props.newsData) {
      payload[EVENT.NEWS_ID] = this.props.newsData.id;
      payload[EVENT.NEWS_NAME] = this.props.newsData.name;
    }
    if (option) {
      payload[EVENT.OPTION_SELECTED] = option.id;
      payload[EVENT.OPTION_SELECTED2] = option.id;
      payload[EVENT.OPTION_RESULT] = option.result;
    }
    payload[EVENT.VIEW_ID] = this.props.viewId;
    payload[EVENT.PARTNER_SOURCE] = this.props.source;
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data;
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    if (this.props.storyData) {
      payload[EVENT.CONTENT_ID] = this.props.storyData.activity
        ? this.props.storyData.activity
        : this.props.storyData.story;
      payload[EVENT.CONTENT_TYPE] = this.props.storyData.activity
        ? "Activity"
        : "Story";
    }

    // payload[EVENT.CONTENT_NAME]=null
    // payload[EVENT.WOW_CODE]=null
    // payload[EVENT.NEWS_NAME]=null
    // payload[EVENT.NEWS_ID]=null
    payload[EVENT.QUESTION_ID] = ques.id;

    Analytics.logEvents(event, payload);
  };

  selectOption = async () => {
    let option = this.state.selectedOption;
    const { questions } = this.props;
    const currentQuestion = questions[this.state.questionNo];

    if (
      currentQuestion.sub_category === "normal" &&
      Object.keys(this.state.selectedOption).length > 0
    ) {
      //FREAD-1119
      //this.setState({ optionSelected: option.id,selectedOption:null,showSubmit:false });
      let result;
      if (this.props.isStory) {
        // Mixpanel.track("");
        const answerPayload = {
          option: option.id,
          question: option.question,
          story: this.props.questions[this.state.questionNo].story,
        };
        result = await this.props.submitAnswer(answerPayload);
        this.setState({ is_valid_answer: result?.result?.is_valid_answer });
      } else {
        // Mixpanel.track(
        //   "Activities Questions Submit Click",
        //   this.props.questions[this.state.questionNo]
        // );
        //FREAD-1350
        let answerPayload = {};
        if (this.props.questions[this.state.questionNo]?.activity) {
          answerPayload = {
            option: option?.id,
            question: option?.question,
            activity: this.props.questions[this.state.questionNo]?.activity,
          };
          result = await this.props.submitActivityQuizAnswerRequest(
            answerPayload
          );
        } else if (this.props.questions[this.state.questionNo]?.news_fread) {
          answerPayload = {
            option: option?.id,
            question: option?.question,
            news_fread: this.props.questions[this.state.questionNo]?.news_fread,
          };
          result = await this.props.submitNewsQuizAnswerRequest(answerPayload);
        } else {
          answerPayload = {
            option: option?.id,
            question: option?.question,
            reading: this.props.questions[this.state.questionNo]?.reading,
          };
          result = await this.props.submitReadingChallengeQuizAnswerRequest(
            answerPayload
          );
        }
        //FREAD-1350

        // result = await this.props.submitActivityQuizAnswerRequest(
        //   answerPayload
        // );
      }
      if (result && result.result) {
        this.sendQuesAnalytics(EVENT.SUBMIT_QUES, currentQuestion, {
          id: option.id,
          result: result.result.is_valid_answer,
        });
      }
      if (result && result?.result?.freadom_point_earned) {
        // Meaning that this was the last question
        await this.setState({ rewardData: result.result });
      }
      //FREAD-1119
      this.setState({
        optionSelected: option.id,
        selectedOption: null,
        showSubmit: false,
      });
    } else if (currentQuestion.sub_category === "reorder") {
      //FREAD-1119
      //this.setState({  selectedOption:null,showSubmit:false });
      let { options } = this.state;
      let result = [];
      let answerArray = [];
      options.forEach((element, index) => {
        result.push({ option: element.id, selected_order: index });
        answerArray[element.order] = element;
      });

      if (this.props.isStory) {
        // Mixpanel.track("");
        const answerPayload = {
          order_answered: result,
          question: currentQuestion.id,
          story: this.props.questions[this.state.questionNo].story,
        };
        result = await this.props.submitAnswer(answerPayload);
      } else {
        // Mixpanel.track(
        //   "Activities Questions Submit Click",
        //   this.props.questions[this.state.questionNo]
        // );
        //FREAD-1350
        let answerPayload = {};
        if (this.props.questions[this.state.questionNo]?.activity) {
          answerPayload = {
            order_answered: result,
            question: currentQuestion.id,
            activity: this.props.questions[this.state.questionNo].activity,
          };
          result = await this.props.submitActivityQuizAnswerRequest(
            answerPayload
          );
        } else if (this.props.questions[this.state.questionNo]?.news_fread) {
          answerPayload = {
            order_answered: result,
            question: currentQuestion.id,
            news_fread: this.props.questions[this.state.questionNo].news_fread,
          };
          result = await this.props.submitNewsQuizAnswerRequest(answerPayload);
        } else {
          answerPayload = {
            order_answered: result,
            question: currentQuestion?.id,
            reading: this.props.questions[this.state.questionNo]?.reading,
          };
          result = await this.props.submitReadingChallengeQuizAnswerRequest(
            answerPayload
          );
        }
        //FREAD-1350
      }
      if (result && result.result) {
        this.sendQuesAnalytics(EVENT.SUBMIT_QUES, currentQuestion, {
          id: option.id,
          result: result.result.is_valid_answer,
        });
      }
      if (result) {
        setTimeout(() => {
          this.setState({
            optionSelected: this.state.options,
            showCorrectOrder: true,
            options: answerArray,
          });
        }, 2000);
        if (result && result?.result?.freadom_point_earned) {
          // Meaning that this was the last question
          await this.setState({ rewardData: result.result });
        }
      }
      //FREAD-1119
      this.setState({ selectedOption: null, showSubmit: false });
    }
  };
  submitAns = (option) => {
    if (this.state.selectedOption != null) {
      this.setState({ selectedOption: option });
      this.setState({ optionSelected: option.id });
    }
  };

  proceedToNext = () => {
    const { questions } = this.props;
    const { rewardData } = this.state;
    if (this.state.questionNo + 1 < this.props.questions.length) {
      this.setState((prevState) => ({
        showCorrectOrder: false,
        optionSelected:
          questions[prevState.questionNo + 1].given_answer ||
          (questions[prevState.questionNo + 1].order_answered &&
            questions[prevState.questionNo + 1].order_answered.length > 0)
            ? true
            : false,
        questionNo: prevState.questionNo + 1,
        showSubmit:
          questions[prevState.questionNo + 1].given_answer ||
          (questions[prevState.questionNo + 1].order_answered &&
            questions[prevState.questionNo + 1].order_answered.length > 0) > 0
            ? false
            : true,
        selectedOption: questions[prevState.questionNo + 1].given_answer
          ? null
          : {},
        options:
          questions[prevState.questionNo + 1].order_answered &&
          questions[prevState.questionNo + 1].order_answered.length > 0
            ? questions[prevState.questionNo + 1].order_answered
            : questions[prevState.questionNo + 1].option,
        is_valid_answer:
          questions[prevState.questionNo + 1].given_answer ===
          questions[prevState.questionNo + 1].valid_option
            ? true
            : false,
      }));
    } else {
      if (rewardData) {
        this.props.finalAnswer(rewardData);
      } else {
        this.props.closeQuiz();
      }
    }
  };

  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ options }) => ({
      options: this.array_move(options, oldIndex, newIndex),
    }));
  };

  render() {
    const { questions } = this.props;
    const {
      questionNo,
      optionSelected,
      showSubmit,
      showCorrectOrder,
      is_valid_answer,
    } = this.state;
    const totalQuestions = questions?.length;
    const currentQuestion = questions[questionNo];
    return questions && currentQuestion ? (
      <div className="component-quiz">
        <strong>QUESTION {questionNo + 1}</strong>/{totalQuestions}
        <div style={{ display: "flex", marginTop: "12px" }}>
          {questions.map((q) => (
            <div
              className={cn("ques-stepper", {
                active: currentQuestion.id === q.id,
              })}
              key={q.id}
            />
          ))}
        </div>
        <div className="question-card-container">
          <div className="question-card">
            {currentQuestion.name}
            <div style={{ textAlign: "center" }}>
              {currentQuestion.question_category === "audio" ? (
                <audio controls>
                  <source src={currentQuestion.media[0]} type="audio/ogg" />
                  <source src={currentQuestion.media[0]} type="audio/mp3" />
                  <source src={currentQuestion.media[0]} type="audio/mpeg" />
                </audio>
              ) : currentQuestion.question_category === "image" ? (
                <img
                  className={"ques-image"}
                  src={currentQuestion.media[0]}
                  alt="question thumbnail"
                ></img>
              ) : null}
            </div>
          </div>
          <div className="question-card-dummy"></div>
        </div>
        {currentQuestion.sub_category === "normal" ? (
          <Row>
            {currentQuestion.option.map((option, index) => (
              <Col span={currentQuestion.option_category === "image" ? 12 : 24}>
                <div
                  className={cn(
                    "quiz-option quiz-option-container",
                    {
                      "option-space":
                        currentQuestion.option_category === "image",
                    },
                    {
                      "bg-correct": this.props.isStory
                        ? optionSelected &&
                          !showSubmit &&
                          (option.id === currentQuestion.valid_option ||
                            (is_valid_answer && option.id === optionSelected))
                        : optionSelected &&
                          !showSubmit &&
                          option.id === currentQuestion.valid_option,
                    },
                    {
                      "bg-selected":
                        this.state.selectedOption &&
                        showSubmit &&
                        option.id === this.state.selectedOption.id,
                    },
                    {
                      "bg-incorrect": this.props.isStory
                        ? optionSelected &&
                          !showSubmit &&
                          optionSelected === option.id &&
                          !is_valid_answer
                        : optionSelected &&
                          !showSubmit &&
                          optionSelected === option.id &&
                          optionSelected !== currentQuestion.valid_option,
                    }
                  )}
                  onClick={() => this.submitAns(option)}
                  key={option.id}
                >
                  {currentQuestion.option_category !== "image" ? (
                    <span>{String.fromCharCode(97 + index)}. </span>
                  ) : null}
                  {currentQuestion.option_category === "audio" ? (
                    <audio controls>
                      <source src={option.media[0]} type="audio/ogg" />
                      <source src={option.media[0]} type="audio/mp3" />
                      <source src={option.media[0]} type="audio/mpeg" />
                    </audio>
                  ) : currentQuestion.option_category === "image" ? (
                    <img
                      src={option.media[0]}
                      width={85}
                      height={85}
                      alt="option thumbnail"
                    ></img>
                  ) : (
                    <span>{option.name}</span>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        ) : currentQuestion.sub_category === "reorder" ? (
          <div>
            {showCorrectOrder ? (
              <Row>
                {this.state.options.map((option, index) => (
                  <Col
                    span={currentQuestion.option_category === "image" ? 12 : 24}
                  >
                    <div
                      className={cn(
                        "quiz-option quiz-option-container bg-correct"
                      )}
                      onClick={() => this.submitAns(option)}
                      key={option.id}
                    >
                      <span>{option.name}</span>
                    </div>
                  </Col>
                ))}
              </Row>
            ) : (
              <SortableList
                items={this.state.options}
                optionSelected={optionSelected}
                flag={showSubmit}
                onSortEnd={this.onSortEnd}
              />
            )}
          </div>
        ) : null}
        {showSubmit ? (
          <div onClick={this.selectOption} className="next-btn">
            Submit
          </div>
        ) : (
          <div onClick={this.proceedToNext} className="next-btn">
            {optionSelected
              ? questionNo === totalQuestions - 1
                ? "Done >"
                : "Next >"
              : ""}
          </div>
        )}
      </div>
    ) : null;
  }
}
const SortableItem = SortableElement(
  ({ optionSelected, flag, position, value }) => (
    <div
      className={cn(
        "quiz-option quiz-option-container",
        {
          "bg-correct": !flag && value.order === position + 1,
        },
        {
          "bg-incorrect": !flag && value.order !== position + 1,
        }
      )}
      style={{ justifyContent: "space-between" }}
    >
      {value.name}
      {flag ? <span>&#8693;</span> : null}
    </div>
  )
);

const SortableList = SortableContainer(({ optionSelected, flag, items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          disabled={!flag}
          optionSelected={optionSelected}
          key={`item-${value.id + value.name}`}
          flag={flag}
          index={index}
          position={index}
          value={value}
        />
      ))}
    </div>
  );
});

export default connect(null, (dispatch) =>
  bindActionCreators(
    { submitAnswer, ...activityActions, ...newsActions },
    dispatch
  )
)(Quiz);
