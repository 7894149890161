import React, { Component } from "react";
import { Button, Modal, Icon } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cn from "classnames";
import * as storyActions from "store/story/actions";
import { storage as LocalStorage } from "services/config/storage";
import Quiz from "components/Quiz";
import MediaViewer from "components/MediaViewer";
import MusicImg from "assets/image/album-music.svg";
import ReadStory from "../../assets/image/read-story.png";
import WatchStory from "../../assets/image/video-story.png";
import BookmarkActive from "assets/image/bookmark-active.svg";
import BookmarkInActive from "assets/image/bookmark-inactive.svg";
import CloseBlackIcon from "assets/image/close-black.svg";
import "./bookSummary.scss";
import ClockIcon from "assets/image/time.svg";
import { Analytics } from "../../services/analytics";
import { v4 as uuidv4 } from "uuid";
import * as EVENT from "../../utils/eventKeys";
const READ = "read";
const LISTEN = "listen";
const WATCH = "watch";
const NOT_STARTED = "not_started";
const ON_GOING = "on_going";
const COMPLETED = "completed";

class bookSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quizOpened: false,
      continueQuiz: false,
      mediaOpen: false,
      isLiked: false,
      storyData: {},
      viewId: uuidv4(),
      total_time: 0,
    };
  }

  setTotalTime = (time) => {
    this.setState({
      total_time : time
    })
  }

  lastQuestionAnswered = async (dataStory) => {
    this.setState({ quizOpened: false });
    let streakPayload = {
      "action_id": this.state.storyData.id,
      "action_type": "story"
    }
    // call the await createStreakRequest to update the streak  
    const { result } = await this.props.createStreakRequest(streakPayload);
    if(!result.IsUpdated){
      // append streak to the dataStory variable
        dataStory.streak = 1;
    }
    this.props.openSuccessModal(dataStory);
  };

  bookmark = async (e) => {
    e.stopPropagation();

    const { bookmarkOnlyStory, dataStory } = this.props;
    const res = await bookmarkOnlyStory(dataStory);
    this.props.bookmark(dataStory.id);
    if (res.success) {
      this.setState((prevState) => ({ isLiked: !prevState.isLiked }));
    }
    this.sendStoryAnalytics(EVENT.LIKE_STORY, this.props.dataStory, true);
  };

  componentDidMount() {
    const { dataStory } = this.props;
    this.setState({total_time : dataStory?.time_spent});
    this.setState({ isLiked: dataStory.is_liked, storyData: dataStory });
    this.sendStoryAnalytics(EVENT.VIEW_STORY, this.props.dataStory);
  }
  componentWillUnmount() {
    const { dataStory } = this.props;
    this.sendStoryAnalytics(EVENT.CLOSE_STORY, dataStory);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.quizOpened !== this.state.quizOpened) {
      if (this.state.quizOpened == false) {
        // this.props.callChasngeTaskStatusRequest(this.state.storyData);
      }
    }
  }
  sendStoryAnalytics = (event, dataStory, isLiked = null) => {
    let payload = {};
    payload[EVENT.STORY_TYPE] = dataStory?.story_type;
    payload[EVENT.STORY_NAME] = dataStory?.name;
    payload[EVENT.STORY_ID] = dataStory?.id;
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data; // create seesion id after login  UUID.randomUUID().toString()
    payload[EVENT.SOURCE_PAGE_ID] = this.props.source; // event source key
    payload[EVENT.PARTNER_SOURCE] = dataStory?.source; // source in dataStory response
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.VIEW_ID] = this.state.viewId; // create a uuid for unique story
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    if (isLiked != null) {
      payload[EVENT.STORY_LIKE] = this.state.isLiked;
    } else {
      payload[EVENT.SECTION_ID] = dataStory?.section_data[0]?.id;
      payload[EVENT.SKILL_TAGS] =
        dataStory?.skill_tag && dataStory?.skill_tags.length > 0
          ? JSON.stringify(dataStory.skill_tags)
          : undefined;
    }
    Analytics.logEvents(event, payload);
  };

  handleStoryReading = async () => {
    
    const res = await this.props.getStoryDetail(this.state.storyData.id);
    console.log("handleStoryReading", res);
    this.setState({ storyData: res, total_time : res?.time_spent});
    const { storyData } = this.state;

    if (storyData) {
      this.sendStoryAnalytics(EVENT.START_STORY, storyData);
    }
    if (storyData.status === NOT_STARTED) {
      // Mixpanel.track("Start Reading", this.props.dataStory);
      const { result } = await this.props.startStoryRequest(storyData);
      if (result) {
        this.props.updateDetailedStory(result.status);
        
        this.setState((prevState) => ({
          storyData: {
            ...prevState.storyData,
            status: result.status,
          },
        }));
      }
    } else if (storyData.status === ON_GOING) {
      // Mixpanel.track("Continue Reading", this.props.dataStory);
    } else if (storyData.status === COMPLETED) {
      // call callChangeTaskStatusRequest
      // this.props.callChangeTaskStatusRequest(storyData);

    }
    if (
      this.props.dataStory.section_data &&
      this.props.dataStory.section_data.length > 0
    ) {
      this.setState({ mediaOpen: true });
    } else {
      alert("Invalid media...");
    }
  };

  getStoryReadingText = () => {
    // const { storyData } = this.state;
    const { dataStory } = this.props;

    if (dataStory.status === NOT_STARTED) {
      //FREAD-1115
      switch (dataStory.story_type) {
        case READ:
          return "Read Story";
        case LISTEN:
          return "Listen to the Story";
        case WATCH:
          return "Watch Story";
        default:
          return "Start";
      }
      //return "Start Reading";
    } else if (dataStory.status === ON_GOING) {
      return "Continue";
    } else if (dataStory.status === COMPLETED) {
      //FREAD-1176
      //return "Read Again";
      switch (dataStory.story_type) {
        case READ:
          return "Read Again";
        case LISTEN:
          return "Listen Again";
        case WATCH:
          return "Watch Again";
        default:
          return "Read Again";
      }
      //FREAD-1176
    }
    return "";
  };

  finishQuiz = async () => {
    // Mixpanel.track("Finish Reading", this.props.dataStory);
    const { storyData } = this.state;
    this.sendStoryAnalytics(EVENT.MARK_COMPLETE_STORY, storyData);
    const { dataStory } = this.props;
    let res = await this.props.finishStoryRequest(storyData.id);
    if (res) {
      if (dataStory.has_mcq) {
        this.setState({ quizOpened: true });
        // Mixpanel.track("QA-After Finish Story", this.props.dataStory);
      } else {
        this.props.updateDetailedStory(res.status);
        this.setState((prevState) => ({
          storyData: {
            ...prevState.storyData,
            status: res.status,
          },
        }));
        let streakPayload = {
          "action_id": storyData.id,
          "action_type": "story"
        }
        // call the createStreakRequest to update the streak 
        const { result } = await this.props.createStreakRequest(streakPayload);
        
        if(!result.IsUpdated){
          // append streak to the dataStory variable
            res.streak = 1;
        }
        this.props.openSuccessModal(
          res.result ? res.result : res.data ? res.data : res
        );
      }
    }
  };
  openQuiz = () => {
    this.setState({ quizOpened: true });
  };
  render() {
    const { dataStory, questions } = this.props;
    const { quizOpened, isLiked, storyData } = this.state;
    return (
      <div className={cn("component-summary", { "quiz-active": quizOpened })}>
        {/* <Icon className="close-icon" type="close" /> */}
        <img
          src={CloseBlackIcon}
          className="close-icon"
          alt="close"
          onClick={this.props.close}
        />
        {/* {quizOpened === false && (
          <React.Fragment> */}
        <div className="flex left">
          <img
            src={dataStory.background_image}
            alt="book-coverImg"
            className="bg-image"
          />
          <div className="mobile-back" onClick={this.props.close}>
            <Icon type="left" />
            <span style={{ textShadow: "0px 2px 3px #333" }}>Back</span>
          </div>
          <div className="mobile-bookmark">
            <img
              src={isLiked ? BookmarkActive : BookmarkInActive}
              alt="bookmark"
              onClick={this.bookmark}
              style={{ margin: "16px", cursor: "pointer" }}
            />
          </div>
          <div className="story-card">
            <div className="name">{dataStory.name}</div>
            <div className="author-name">
              Written by <b>{dataStory.author_name}</b>
            </div>
          </div>
        </div>
        {/* </React.Fragment>
        )} */}
        <div className="division" />
        <div className="flex description-card">
          {quizOpened === false && (
            <React.Fragment>
              <div>
                <img
                  src={isLiked ? BookmarkActive : BookmarkInActive}
                  alt="bookmark"
                  onClick={this.bookmark}
                  style={{ margin: "16px", cursor: "pointer" }}
                  className="bookmark-icon"
                />
                <div className="flex summary-card">
                  <span className="title">DESCRIPTION</span>
                  <div className="summary">{dataStory.description}</div>
                </div>
                {dataStory.story_type === LISTEN && (
                  <div className="audioBook">
                    <img
                      src={MusicImg}
                      alt="album-music"
                      style={{ marginRight: "5px" }}
                    />
                    <span className="text">Audiobook</span>
                  </div>
                )}
                {dataStory.story_type === READ && (
                  <div className="audioBook">
                    <img
                      src={ReadStory}
                      alt="Read"
                      style={{ marginRight: "5px", width: '20px' }}
                    />
                    <span className="text">Read</span>
                  </div>
                )}
                {dataStory.story_type === WATCH && (
                  <div className="audioBook">
                    <img
                      src={WatchStory}
                      alt="video"
                      style={{ marginRight: "5px", width: '20px' }}
                    />
                    <span className="text">Watch</span>
                  </div>
                )}
                {dataStory.reading_time ? (
                  <div className="read-time">
                    <img
                      src={ClockIcon}
                      style={{
                        margin: dataStory.reading_time ? "0 6px 0 0" : "auto",
                      }}
                      alt=""
                    />
                    <div>{dataStory.reading_time || ""}</div>
                  </div>
                ) : null}
              </div>
              <div className="btn_last">
                <Button
                  type="primary"
                  className="primary-btn"
                  size="large"
                  onClick={this.handleStoryReading}
                >
                  {this.getStoryReadingText()}
                </Button>
                {dataStory.status === ON_GOING && (
                  <Button
                    type="primary"
                    className="primary-btn ml-16"
                    size="large"
                    //style={{ marginLeft: "16px" }}
                    onClick={this.finishQuiz}
                  >
                    Finish
                  </Button>
                )}
                {dataStory.has_mcq && dataStory.status === COMPLETED && (
                  <Button
                    type="primary"
                    className="primary-btn ml-16"
                    size="large"
                    onClick={() => this.openQuiz()}
                    //style={{ marginLeft: "16px" }}
                  >
                    {
                      //console.log(questions)
                    }
                    {dataStory.is_quiz_taken
                      ? "Review Quiz"
                      : typeof questions !== "undefined" &&
                        (questions[0]?.given_answer != null ||
                          questions[0]?.order_answered != null)
                      ? "Continue Quiz"
                      : "Start Quiz"}
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
          {quizOpened && (
            <Quiz
              viewId={this.state.viewId}
              source={this.props.source}
              questions={questions}
              finalAnswer={this.lastQuestionAnswered}
              closeQuiz={() => this.setState({ quizOpened: false })}
              storyData={storyData}
              isStory={true}
            />
          )}
        </div>
        <Modal
          centered
          width="100%"
          className="media-container"
          visible={
            this.state.mediaOpen &&
            dataStory.section_data &&
            dataStory.section_data.length > 0
          }
          onCancel={() => this.setState({ mediaOpen: false })}
          footer={null}
          key={1}
          destroyOnClose
          closable={false}
        >
          <MediaViewer
            data={dataStory.section_data}
            setTotalTime={this.setTotalTime}
            total_time={this.state.total_time}
            closeMedia={() => this.setState({ mediaOpen: false })}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(
  ({ detailedStory }) => ({ ...detailedStory }),
  (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(bookSummary);
