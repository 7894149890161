import React, { useState, useEffect } from "react";
import { Input, List, Avatar } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getContentSearch } from "../../services/story";
import * as storyActions from "store/story/actions";
import { Spin } from "antd";
import "./Navbar.scss";
import * as EVENT from "../../utils/eventKeys";
import { Analytics } from "../../services/analytics";
import { storage as LocalStorage } from "services/config/storage";
import _debounce from "lodash/debounce";

let Timer;

function ContentSearch({ shwContentSearch, topStory, selectOption, match }) {
  const [shwCat, setShwCat] = useState(false);
  const [srchVal, setSrchVal] = useState("");
  const [type, setType] = useState("");
  const [offset, setOffset] = useState(0);
  const [has_next, setHas_next] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [lists, setList] = useState([]);

  useEffect(() => {
    getLists(srchVal, type, setList, setOffset, setHas_next);
  }, [type, srchVal]);

  useEffect(() => {
    if (offset !== 0 && isLoading) {
      add_next_lists(
        srchVal,
        type,
        setList,
        lists,
        offset,
        has_next,
        setLoading,
        setOffset,
        setHas_next
      );
    } else setLoading(false);
  }, [isLoading]);
  return (
    <>
      <div
        className="Srch_main"
        style={{
          display: shwContentSearch ? "block" : "none",
        }}
      >
        <Input
          allowClear={true}
          value={srchVal}
          className="input-search"
          onPressEnter={(e) => SearchKeyUp(e.target.value, match.path)}
          onChange={(e) => {
            setShwCat(e.target.value !== "" ? true : false);
            setSrchVal(e.target.value);
            getCount(e.target.value, setTypes, setType);
            //getLists(srchVal,setType,'story',setList);
          }}
          placeholder="Search for Stories, Activities and News"
        />
        <div className="filter-relate">
          <div
            className="filter-absolute"
            style={{
              display: shwCat ? "block" : "none",
            }}
          >
            <div className="srch_cat">
              {types.filter((type) => type.name === "Stories").length > 0 ? (
                <button
                  onClick={() => {
                    setType("story");
                  }}
                  className={"btn-cat " + (type === "story" ? "actives" : "")}
                >
                  Stories (
                  {
                    types.filter((type) => type.name === "Stories")[0]
                      ?.doc_count
                  }
                  )
                </button>
              ) : (
                <button
                  onClick={() => {
                    setType("story");
                  }}
                  className={"btn-cat " + (type === "story" ? "actives" : "")}
                >
                  Stories (0)
                </button>
              )}
              {types.filter((type) => type.name === "Activities").length > 0 ? (
                <button
                  onClick={() => {
                    setType("activity");
                  }}
                  className={
                    "btn-cat " + (type === "activity" ? "actives" : "")
                  }
                >
                  Activities (
                  {
                    types.filter((type) => type.name === "Activities")[0]
                      ?.doc_count
                  }
                  )
                </button>
              ) : (
                <button
                  onClick={() => {
                    setType("activity");
                  }}
                  className={
                    "btn-cat " + (type === "activity" ? "actives" : "")
                  }
                >
                  Activities (0)
                </button>
              )}
              {types.filter((type) => type.name === "News Freads").length >
              0 ? (
                <button
                  onClick={() => {
                    setType("news_fread");
                  }}
                  className={
                    "btn-cat " + (type === "news_fread" ? "actives" : "")
                  }
                  type="button"
                >
                  News (
                  {
                    types.filter((type) => type.name === "News Freads")[0]
                      ?.doc_count
                  }
                  )
                </button>
              ) : (
                <button
                  onClick={() => {
                    setType("news_fread");
                  }}
                  type="button"
                  className={
                    "btn-cat " + (type === "news_fread" ? "actives" : "")
                  }
                >
                  News (0)
                </button>
              )}
            </div>
            <div>
              {!types.filter((type) => type.name === "Stories").length > 0 &&
                type === "story" && (
                  <div className="teacher-no-data">No Data</div>
                )}
              {!types.filter((type) => type.name === "Activities").length > 0 &&
                type === "activity" && (
                  <div className="teacher-no-data">No Data</div>
                )}
              {!types.filter((type) => type.name === "News Freads").length >
                0 &&
                type === "news_fread" && (
                  <div className="teacher-no-data" style={{ color: "#9134d8" }}>
                    No Data
                  </div>
                )}
              {type !== "" && lists.length > 0 && (
                <div
                  className="stories_list list_flow"
                  onScroll={(event) =>
                    Scroll_Result(event, setLoading, has_next)
                  } //style={{display:shwstory?'block':'none'}}
                >
                  <div>
                    <List
                      itemLayout="horizontal"
                      dataSource={lists}
                      renderItem={(story) => (
                        <List.Item
                          style={{ pointer: "cursor" }}
                          onClick={() => {
                            //alert(story.name)
                            SearchKeyUp(story?._source?.name, match.path);
                            selectOption([
                              {
                                _index: story?._index,
                                _source: { id: story?._id },
                                _type: story?._type,
                                _name: story?._source?.name,
                              },
                            ]);
                          }}
                        >
                          <List.Item.Meta
                            avatar={<Avatar src={story?._source?.image} />}
                            title={story?._source?.name}
                            description=""
                          />
                        </List.Item>
                      )}
                    />
                    {isLoading && (
                      <div
                        className="teacher-no-data"
                        style={{ color: "#9134d8" }}
                      >
                        Loading ...
                        <Spin />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const Scroll_Result = (e, setLoading, has_next) => {
  const el = e.target; //.documentElement;
  //return console.log(el);
  const bottom = el.scrollHeight - el.scrollTop === el.clientHeight;
  if (bottom) {
    console.log("we are in the bottom");
    //Offset code below
    has_next && setLoading(true);
  }
};
const getCount = _debounce(async (value, setTypes, setType) => {
  let queryParams = "query=" + value;
  let res = await getContentSearch(queryParams);
  if (res?.result?.counts) {
    setTypes(res.result?.counts);
    setType("story");
  }
},500)

const getLists = _debounce(async (value, index_type, setList, setOffset, setHas_next) => {
  setList([]);
  if (value === "" || index_type === "") return;

  let queryParams = "query=" + value + "&index_type=" + index_type;
  let res = await getContentSearch(queryParams);

  if (res?.result?.documents?.length > 0) {
    setList(res?.result?.documents);
    setOffset(res?.next_page);
    setHas_next(res?.has_next);
  } else {
    setList([]);
  }
},500)
const add_next_lists = async (
  value,
  index_type,
  setList,
  lists,
  offset,
  has_next,
  setLoading,
  setOffset,
  setHas_next
) => {
  //setList([]);
  if (value === "" || index_type === "") return setLoading(false);

  let queryParams =
    "query=" + value + "&index_type=" + index_type + "&offset=" + offset;
  let res = await getContentSearch(queryParams);
  //console.log('Alert',res)
  if (res?.result?.documents?.length > 0) {
    setOffset(res?.next_page);
    setHas_next(res?.has_next);
    setList([...lists, ...res?.result?.documents]);
    setLoading(false);
  } else {
    setLoading(false);
  }
};

const SearchKeyUp = (value, path) => {
  if (value !== "") {
    clearTimeout(Timer);
    Timer = setTimeout(sendSearchEvent(value, path), 500);
  }
};

const sendSearchEvent = (srchVal, path) => {
  let payload = {};
  payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
  payload[EVENT.SOURCE_PAGE_ID] = getSourcePage(path);
  payload[EVENT.SEARCH_TEXT] = srchVal;

  Analytics.logEvents(EVENT.SEARCH, payload);
};

const getSourcePage = (path) => {
  switch (path) {
    case "/activities":
      return EVENT.SOURCE_PAGE_ACTIVITIES;
    case "/collection/:id":
      return EVENT.SOURCE_PAGE_COLLECTION;
    case "/story":
      return EVENT.SOURCE_STORY_READING;
    case "/":
      return EVENT.SOURCE_FEED_PAGE;
    default:
      return "";
  }
};

export default connect(
  ({ story, activity }) => ({ ...story, ...activity }),
  (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(withRouter(ContentSearch));
