import { Api } from "./config/request";
import paramObjToString from "./utils";

export const getActivitiesList = () => Api.getListRequest("activity/v1/");

export const getActivityPackList = param =>
  Api.getListRequest(`activity/v1/pack/?${paramObjToString(param)}`);

export const getReadingChallengeList = () => Api.getRequest('reading/v1/');

export const getActivityDetail = id => Api.getListRequest(`activity/v1/${id}`);

export const getActivityQuestions = id =>
  Api.getListRequest(`assessment/v1/activity/${id}/question/`)

export const submitActivityQuizAnswer = payload => Api.postRequest('assessment/v1/assessment/', payload);
//FREAD-1350
export const submitReadingChallengeQuizAnswer = payload => Api.postRequest('reading-assessment/v1/answer/', payload);
//FREAD-1350

export const getActivityPackDetails = payload => Api.getListRequest(`activity/v1/pack/${payload.id}/?grade_id=${payload.gradeId}`);

export const unlockActivityPack = payload => Api.postRequest('activity/v1/pack/purchase/', payload);

export const getReadingChallengeDetails = (id) => Api.getRequest(`reading/v1/${id}`);

export const getReadingChallengeQuestions = id => Api.getListRequest(`reading-assessment/v1/reading/${id}/question`);

export const createStreak = (payload) => Api.postRequest('user/v1/streak/', payload);

export const Activities = {
  //FREAD-1350
  submitReadingChallengeQuizAnswer,
  //FREAD-1350
  getActivitiesList,
  getActivityPackList,
  getReadingChallengeList,
  getActivityDetail,
  getActivityQuestions,
  submitActivityQuizAnswer,
  getActivityPackDetails,
  unlockActivityPack,
  getReadingChallengeDetails,
  getReadingChallengeQuestions,
  createStreak,
};
