import React, { useEffect, useState, useLayoutEffect } from "react"
import Navbar from "components/Navbar";
// import BannerFront from "../../assets/Report/Capture.png";
import ReportImage from "../../assets/Report/Group.png"
import Table from 'react-bootstrap/Table'
import Icon from "../../assets/Report/icon.png"
import Icon1 from "../../assets/Report/icon1.png"
import Icon2 from "../../assets/Report/icon2.png"
import Icon3 from "../../assets/Report/icon3.png"
import Icon4 from "../../assets/Report/icon4.png"
// import BannerLast from "../../assets/Report/Capture123.png"
import Logo from "../../assets/Report/Logo.png";
import Side from "../../assets/Report/Side.png";
import Side1 from "../../assets/Report/Side1.png";
import Upper from "../../assets/Report/upper.png";
import UpperMobile from "../../assets/Report/bg.png";
import Bannerlast1 from "../../assets/Report/Bannerlast1.png";
import Bannerlastmob from "../../assets/Report/Bannerlastmob.png";


import * as cmfluencyAction from "../../store/cmfluency/actions";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { storage as LocalStorage } from "services/config/storage";
import Loader from 'components/Loader';
import "./index.scss"
import QuizBanner from "../QuizSection/QuizBanner";
import MobileDetect from "mobile-detect";
import useDeviceDetect from "../../hooks/useDeviceDetect";



const CMFReport = (props) => {
  const [ deviceType ] = useDeviceDetect();
  const [data, setData] = useState(" ")
  const childList = LocalStorage.fetch.defaultChild();
  console.log("deviceType=>>>>>>>>>>",deviceType);
  useLayoutEffect(() => {
    async function fetchMyAPI() {
      let response = await props.getReportData(childList.id);
      setData(response.data[response.data.length - 1])

    }
    fetchMyAPI()
  }, [])


  useEffect(() => {
    return () => {
      props.clearReportData();
    }
  }, [])

  console.log("data", data.poor_word)

  return (
    <div>
      {Object.keys(props.reportData).length < 1 ? (
        <Loader />
      ) :
        <div className="cmf-report-container" >
          <div className="upper">
            <Navbar hideForCMFluency={true} />
          </div>
          <div className="bannerImageContainer" >
            <img className="bannerImage" src = { deviceType === "mobile" ? UpperMobile : Upper}/>
          </div>
          <div className="uppertext" style={{ paddingLeft: "7%", paddingTop: "5%", marginLeft: "12%" }}>
            <div style={{ display: "flex" }}>
              <h6>Name:</h6>
              <h5 className="heading1">{data.child_name}</h5>
            </div>
            <div style={{ display: "flex" }}>
              <h6 style={{ marginTop: "0.3%" }}>Passage Name:</h6>
              <h5 className="heading2">{data.passage_name}</h5>
              <h6 style={{ marginLeft: "6%", marginTop: "0.3%" }}>Noise Level:</h6>
              <h5 className="heading2">{data.noise_level}</h5>
            </div>
          </div>
          <hr className="hrline1"></hr>
          <div className="row">
            <div className="col-2">
              <img className="side" src={Side1} />
            </div>
            <div className="col-8">
              <div style={{ marginLeft: "3%" }}>
                <p>Your child <strong>{data.child_name}</strong> read the passage  <strong>{data.passage_name}</strong> in <strong>{data.audio_len}</strong></p>
                <p>{data.child_name} spoke a total of <strong>{data.word_count} words.</strong> </p>
              </div>
              <hr className="hrline"></hr>
              <div style={{ display: "flex", marginLeft: "3%" }}>
                <p>Words <strong>{data.child_name} </strong>should practice more:</p>
                <h5 className="heading3">{data.poor_word + " "} </h5>
              </div>
              <p style={{ marginLeft: "3%" }}>Reading Accuracy (words read correctly per minute) of <strong>{data.child_name}</strong> is <strong>{data.wcpm}</strong> which is {data.wcpm_text} class average of <strong>{data.wcpm_avg}</strong></p>
              <div >
                <img className="reportImage" src={ReportImage} />
                <div className="tablecontainer">
                  <Table bordered hover style={{ borderRadius: '20px', overflow: "hidden", backgroundColor: "whitesmoke" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "45%" }}>METRICS</th>
                        <th style={{ width: "29%" }}>SCORE</th>
                        <th>CLASS AVERAGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tableData"><span className="tableHeading">Pronunciation Accuracy</span><br /> <span className="tableSubHeading" >Quality of pronunciation of each sound and word </span></td>
                        <td><span className="data1">{data.score}</span></td>
                        <td colSpan={2}><span className="data1">{data.pronunciation_avg}</span></td>
                      </tr>
                      <tr>
                        <td className="tableData"><span className="tableHeading">Reading Fluency</span><br /> <span className="tableSubHeading" > Reading with pace, accuracy and expression </span> </td>
                        <td><span className="data1">{data.fluency_score}</span></td>
                        <td colSpan={2}><span className="data1">{data.fluency_avg}</span></td>
                      </tr>
                      <tr>
                        <td className="tableData"><span className="tableHeading">Reading pace</span><br /> <span className="tableSubHeading" > Speed of reading </span></td>
                        <td><span className="data1">{data.reading_pace}</span></td>
                        <td colSpan={2}>-</td>
                      </tr>
                      <tr>
                        <td ><span className="tableHeading">Pitch / Tone</span><br /><span className="tableSubHeading">Degree of loudness or softness of voice</span></td>
                        <td><span className="data1">{data.tone}</span></td>
                        <td colSpan={2}>-</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div>
                <img style={{ marginLeft: "3%", marginRight: "1%", float: "left" }}
                  src={Icon} />
                <h5 style={{ marginTop: "0.3%" }}>What does this Mean</h5>
                <div style={{ marginLeft: "3%", marginTop: "2%", position: "absolute" }}>
                  <p>Your child’s pronunciation accuracy is <strong>{data.pronunciation_text} grade average</strong> </p>
                  <p>Your child’s reading fluency is <strong>{data.fluency_text} grade average</strong></p>
                  <p>Your child’s reading pace is <strong>{data.reading_pace}</strong></p>
                  <p>Your child’s pitch / tone of speaking is <strong>{data.tone}</strong></p>
                </div>
              </div>
            </div>
            <div className="col-2">
              <img className="side1" src={Side} />
            </div>
          </div>
          <div className="bannercontainer">
            <p className="paracontain" >Recommendations for <strong>{data.child_name}</strong> to become the absolute best</p>
            <div className="paracontainer">
              <div style={{ marginBottom: "5%" }}>
                <img className="icon1" src={Icon1} />
                <p className="paratext">With you, your child sets a reading goal, like stories read per <br />week, and <strong>track it on the Freadom app.</strong></p>
                <img className="icon1" src={Icon3} />
                <p className="paratext">Your child shares their feedback on what they have read, what <br /> they learnt and what they would like <strong>to know more orally.</strong></p>
              </div>
              <div style={{ marginTop: "5%" }}>
                <img className="icon1" src={Icon2} />
                <p className="paratext">Your child plays <strong>word games and solves comprehension</strong> <br />based activities on the Freadom app.</p>
                <img className="icon1" src={Icon4} />
                <p className="paratext">Your child explains what they have learned in the Freadom<br /> Live class to someone at home regularly to revise concepts <br /> build comprehension and <strong>enhance speaking skills.</strong></p>
              </div>
            </div>
          </div>
          <div className="bannerImage1Container">
            {/* <div className="bannerImage1"> </div> */}
            <img className="bannerImage1" src = { deviceType === "mobile" ? Bannerlastmob : Bannerlast1} />
          </div>
          <div className="footer-container">
            <img className="footer-logo" src={Logo} />
            <div className="footertext">
              <p className="footerpara">© 2021, Stones2Milestones. All rights reserved.<br /><span className="footerpara1">Reach us: go@getfreadom.com</span></p>
              <p className="footerpara1"></p>
            </div>
          </div>
        </div>
      }
    </div>

  )
}



export default connect(
  ({ cmfluency, user }) => ({
    ...cmfluency,
    user: { ...user }
  }),
  dispatch => bindActionCreators({ ...cmfluencyAction }, dispatch)
)(withRouter(CMFReport));