const setSearchResult = (data) => ({
    type: "SET_SEARCH_RESULT",
    data,
  });
  const setLevels = (data) => ({
    type: "SET_LEVELS",
    data,
  });

  export const mutation = {
    setSearchResult,
    setLevels
  };