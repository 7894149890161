import * as EVENT from "./eventKeys";
import { storage as LocalStorage } from "services/config/storage";
import { Analytics } from '../services/analytics';
const child = LocalStorage.fetch.defaultChild();
const parent = LocalStorage.fetch.parentData();
const session = LocalStorage.fetch.sessionData();

export const communityAnalytics = () =>{

    let payload = {};
    payload[EVENT.PHONE] = parent?.contact_no;
    payload[EVENT.EMAIL] = parent?.email
    payload[EVENT.CHILD_ID] = child?.id
    payload[EVENT.PARENT_ID] = parent?.id
    payload[EVENT.CHILD_LEVEL] = child?.grade_level
    payload[EVENT.SESSION_ID] = session?.session_data
    payload[EVENT.TIMESTAMP]= new Date();
    Analytics.logEvents(EVENT.FREADOM_COMMUNITY,payload);
}

export const cmFluencyBannerAnalytics = (view_id) =>{
    let payload = {};
    payload[EVENT.SOURCE_PAGE_ID] = EVENT.SOURCE_PAGE_ACTIVITIES;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level
    payload[EVENT.CHILD_ID] = child?.id
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.SESSION_ID] = session?.session_data
    payload[EVENT.VIEW_ID]=view_id
    Analytics.logEvents(EVENT.FLUENCY_TEST_BANNER,payload);
}

export const cmFluencyTestStartAnalytics = (view_id) =>{
    let payload = {};
    payload[EVENT.SOURCE_PAGE_ID] = EVENT.SOURCE_PAGE_ACTIVITIES;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level
    payload[EVENT.CHILD_ID] = child?.id
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.SESSION_ID] = session?.session_data
    payload[EVENT.VIEW_ID]=view_id;
    Analytics.logEvents(EVENT.FLUENCY_TEST_START,payload);
}

export const cmFluencyTestStartRecordingAnalytics = (view_id) =>{
    let payload = {};
    payload[EVENT.SOURCE_PAGE_ID] = EVENT.SOURCE_PAGE_ACTIVITIES;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level;
    payload[EVENT.CHILD_ID] = child?.id;
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.SESSION_ID] = session?.session_data;
    payload[EVENT.VIEW_ID]=view_id;
    Analytics.logEvents(EVENT.FLUENCY_TEST_START_RECORDING,payload);
}

export const cmFluencyTestFinalSubmissionAnalytics = (view_id, user_data) =>{
    let payload = {};
    payload[EVENT.SOURCE_PAGE_ID] = EVENT.SOURCE_PAGE_ACTIVITIES;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level
    payload[EVENT.CHILD_ID] = child?.id
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.SESSION_ID] = session?.session_data;
    payload[EVENT.VIEW_ID]=view_id;
    payload[EVENT.EMAIL] = user_data?.email;
    payload[EVENT.PHONE] = parent?.mobile_number;
    payload[EVENT.NAME] = parent?.child_name;
    Analytics.logEvents(EVENT.FLUENCY_TEST_FINAL_SUBMISSION,payload);
}

export const cmFluencyTestViewReportAnalytics = (view_id) =>{
    let payload = {};
    payload[EVENT.SOURCE_PAGE_ID] = EVENT.SOURCE_PAGE_ACTIVITIES;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level
    payload[EVENT.CHILD_ID] = child?.id
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.SESSION_ID] = session?.session_data;
    payload[EVENT.VIEW_ID]=view_id;
    Analytics.logEvents(EVENT.FLUENCY_TEST_VIEW_REPORTS,payload);
}

export const SchoolCodeAnaltytics = ( schoolData ) =>{
    let payload = {};
    payload[EVENT.SCHOOL_CODE] = schoolData.school_code;
    payload[EVENT.SCHOOL_TAG] = schoolData.school_tag;
    payload[EVENT.CHILD_SCHOOL] = child?.id
    payload[EVENT.SESSION_ID] = session?.session_data;
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.CHILD_ID]= child?.id;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level
    Analytics.logEvents(EVENT.ONBOARDING_SCHOOLCODE,payload);
}

export const SchoolCodeSelectedGradeAnaltytics = (gradeData) =>{
    let payload = {};
    payload[EVENT.CHILD_ID]= child?.id;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level
    payload[EVENT.GRADE] = gradeData.name;
    payload[EVENT.SESSION_ID] = session?.session_data;
    payload[EVENT.TIMESTAMP]= new Date();
    Analytics.logEvents(EVENT.ONBOARDING_SELECT_GRADE,payload);
}

export const SchoolCodeSelectedSectionAnaltytics = (sectionData , schoolCode) =>{
    let payload = {};
    payload[EVENT.CHILD_ID]= child?.id;
    payload[EVENT.CHILD_LEVEL] = child?.grade_level;
    payload[EVENT.GRADE] = schoolCode;
    payload[EVENT.SCHOOL_SECTION] = sectionData.name;
    payload[EVENT.SESSION_ID] = session?.session_data;
    payload[EVENT.TIMESTAMP]= new Date();
    Analytics.logEvents(EVENT.ONBOARDING_SELECT_SECTION,payload);
}