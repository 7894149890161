import React from 'react'
import { useState } from "react";
import { pdfjs, Page, Document } from "react-pdf";

const PdfView = ({ url }) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const [totalPages, setTotalPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageScale, setPageScale] = useState(0.75);

    function onDocumentLoadSuccess({ numPages }) {
        setTotalPages(numPages);
    }
    function onDocumentLoadError(error) {
        console.error('Failed to load PDF:', error);
    }

    function handleZoomIn() {
        if (pageScale < 3.0) {
            setPageScale(pageScale + 0.2);
        }

    }

    function handleZoomOut() {
        if (pageScale>0.75) {
            setPageScale(pageScale - 0.2);
        }
    }

    function handleNext() {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1);
            setPageScale(0.75)
        }
    }
    function handlePrevious() {
        if (pageNumber > 0) {
            setPageScale(0.75)
            setPageNumber(pageNumber - 1);
        }
    }
    return (
        <div className="pdf-container">
            {
                window.innerWidth > 650 ?
                    <>
                        <div className='pdf_page'>
                            <button className='pdf_control_btn' onClick={handlePrevious} disabled={pageNumber === 1}>
                                ‹
                            </button>

                            <div className="pdf-page-content">
                                <Document file={url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                                    <Page pageNumber={pageNumber} scale={pageScale} />
                                </Document>
                            </div>
                            <button className='pdf_control_btn' onClick={handleNext} disabled={pageNumber === totalPages}>
                                ›
                            </button>
                        </div>
                        <div className="pdf-toolbar">
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handleZoomIn} disabled={pageScale >= 3}>
                                    +
                                </button>
                            </div>
                            <div className="pdf-page-count">
                                Page {pageNumber} of {totalPages}
                            </div>
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handleZoomOut} disabled={pageScale <= 0.75}>
                                    -
                                </button>
                            </div>
                        </div>
                    </>

                    :

                    // <div className="pdf-container">

                    <>
                        <div className="pdf-page-content">
                            <Document file={url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                                <Page pageNumber={pageNumber} scale={pageScale} />
                            </Document>
                        </div>
                        <div className="pdf-toolbar">
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handlePrevious} disabled={pageNumber === 1}>
                                    ‹
                                </button>
                                <button className='pdf_control_btn' onClick={handleZoomIn} disabled={pageScale >= 3}>
                                    +
                                </button>
                            </div>
                            <div className="pdf-page-count">
                                Page {pageNumber} of {totalPages}
                            </div>
                            <div className="button-container">
                                <button className='pdf_control_btn' onClick={handleZoomOut} disabled={pageScale <= 0.75}>
                                    -
                                </button>
                                <button className='pdf_control_btn' onClick={handleNext} disabled={pageNumber === totalPages}>
                                    ›
                                </button>
                            </div>
                        </div>
                    </>
                // </div>
            }
        </div>
    );
}

export default PdfView