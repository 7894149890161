import axios from "axios";
import { Api } from "./config/request";

export const getPassage = (params) =>
  Api.getExternalRequest(`https://fluency.getfreadom.com/cmfluency/${params}`);

export const getReportData = (params) =>
  Api.getExternalRequest(
    `https://fluency.getfreadom.com/cmfluency/fluencyreports?freadom_id=${params}`
  );

export const storeCMFluencyData = async (payload) => {
  const response = await axios.post(
    "https://fluency.getfreadom.com/cmfluency/iitb_fluency",
    payload
  );
  return response;
};

export const uploadAudio = (payload) =>
  Api.postRequest("misc/v1/media/", payload);

export const cmfCommunication = (payload) =>
  Api.postRequest("misc/v1/cmf-module/communication/", payload);

export const verifySchoolCode = (code) =>
  Api.getRequest(`school/v1/validate-school-code/?school_code=${code}`);

export const CMFluencyApiCalls = {
  getPassage,
  storeCMFluencyData,
  uploadAudio,
  cmfCommunication,
  verifySchoolCode,
  getReportData,
};
