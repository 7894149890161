import React, { Component } from "react";
import { Avatar, Dropdown, Icon, Modal } from "antd";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { storage as LocalStorage } from "services/config/storage";
import { bindActionCreators } from "redux";
import * as userActions from "store/user/actions";
import * as teacherActions from "store/teacher/actions";
import * as storyActions from "store/story/actions";
import Rotary from "assets/image/rotary_logo.png";
// import FreadomIcon from "assets/image/new-logo-icon.svg";
import FreadomIcon from "assets/image/freadom_logo.png";
// import FliveIcon from "assets/image/nav-fLive.png";
import CommunityIcon from "assets/image/nav-community.png";
import FeedIcon from "assets/image/nav-feed.png";
import TrophyIcon from "assets/image/trophy.svg";
import guided_path from "assets/image/guided_path.svg";
// import StoryIconV2 from "assets/imagev2/story.png";
// import ActivityIconV2 from "assets/imagev2/activity.png";
// import FeedIcon from "assets/image/feed.png";
import StoryIcon from "assets/image/nav-stories.png";
import NewsIcon from "assets/image/news-icon.png";
import SearchIcon from "assets/image/icons/search.png";
import ActivityIcon from "assets/image/nav-activity.png";
import QuizIcon from "assets/image/dice.png";
import GrowthIcon from "assets/image/nav-growth.png"
// import useLongPress from "../../services/useLongPress";
// import oneBr from "assets/image/icons/1br.png";
// import community from "assets/image/icons/community.png";
import "./Navbar.scss";
// import Select from "react-dropdown-select";
// import { Mixpanel } from "../../Mixpanel";
import BookSummary from "components/bookSummary";
import * as activityActions from "store/activities/actions";
import * as homeActions from "store/home/actions";
import ActivityModal from "components/activityModal";
import SettingsIcon from "assets/image/settings-menu.png";
import LogoutIcon from "assets/image/logout-menu.png";
import store from "../../store";
import * as EVENT from "../../utils/eventKeys";
import { Analytics } from "../../services/analytics";
import ParentProfile from "../ParentProfile";
import ContentSrch from "./ContentSrch";
import TeacherSearch from "./Teacher_Search";
import { getSourcePage } from "../../utils/commonUtils";
import { communityAnalytics } from "../../utils/AnalyticsHelper";

let level = [
  { name: "Stories", value: "story", selected: true },
  {
    name: "Activities",
    value: "activity",
    selected: false,
  },
];
var jwt = require("jsonwebtoken");
var privateKey = "056c1e10b04954ccdae40b564ffcff4882892697044a6ede";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.handleonMouseLeaveRelease = this.handleonMouseLeaveRelease.bind(this);
  }

  state = {
    switchingChild: false,
    navbarOpened: false,
    defaultChildData: {},
    resultList: [],
    overallSearchResult: [],
    showSearch: false,
    selectedResult: null,
    isModalOpen: false,
    filterData: level,
    selectedFilter: level[0],
    searchString: null,
    shwContentSrch: false,
    selectedLevel: null,
    activeActivityPackData: null,
    isActivityModalOpen: false,
    activeActivityData: {},
    activeActivityQuestionsList: [],
    successModalOpened: false,
    successModalData: {},
    isActivityPack: false,
    activeReadingChallengeData: {},
    activeReadingChallengeQuestionsList: [],
    isReadingChallenge: false,
    section: null,
    openParentProfile: false,
    emailCheckFor: "profileSetting",
    navbarLinks: [
      // {
      //   title: 'Home',
      //   url: '/',
      //   icon: HomeIcon
      // },
      {
        title: "Feed",
        url: ["/"],
        icon: FeedIcon,
      },
      {
        title: "Guided Path",
        url: ["/guidedPath"],
        icon: guided_path,
      },
      {
        title: "Stories",
        url: ["/story"],
        icon: StoryIcon,
      },
      {
        title: "News",
        url: ["/news"],
        icon: NewsIcon,
      },
      {
        title: "Activities",
        url: ["/activities"],
        icon: ActivityIcon,
      },
      //FREAD-1270
      {
        title: "Growth",
        url: ["/Leaderboard"],
        icon: GrowthIcon,
      },
      {
        title: "Play",
        url: ["/quiz"],
        icon: QuizIcon,
      },
      //FREAD-1270
      //FREAD-1196
      // {
      //     title: 'Play',
      //     url: ['/quiz'],
      //     icon: QuizIcon
      // }
      //FREAD-1196
    ],

    navbarLinks2: [
      //   {
      //     title: "fLIVE",
      //     url: [
      //       "/flive",
      //       "/student/profile/:edit",
      //       "/student/profile",
      //       "/profile-complete",
      //       "/user",
      //       "/user/reports",
      //     ],
      //     icon: FliveIcon,
      //   },
    ],
    navbarLinks3: [],
  };
  handleSwitchChild = async (id) => {
    // Mixpanel.track('Child Switch icon click')
    userActions.batchIdUpdate(id);
    // this.logAnalytics('Switch child page')
    Analytics.logEvents(EVENT.SWITCH_CHILD, {
      "parent ID": LocalStorage.fetch.parentData()?.id,
    });
    this.setState({ switchingChild: true });
    // await this.props.switchChild({ child_id: id });
    let res = await this.props.switchChild({ child_id: id });
    if (res?.status === 404) {
      this.setState({ switchingChild: false });
      //FREAD-1144
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/activities" &&
        window.location.pathname.includes("/activity") &&
        window.location.pathname.includes("/quiz") &&
        window.location.pathname.includes("/feed") &&
        window.location.pathname.includes("/story")
      ) {
        //if (window.location.pathname !== '/' && window.location.pathname !== '/activities') {
        //FREAD-1144
        //if child not onboarded and if tries to go to child's profile, then route to /flive
        this.props.history.push("/flive");
        if (window.location.pathname !== "/flive")
          //FREAD-1447
          //return this.props.history.go(0);

          return window.location.reload();
        //FREAD-1447
      }
      //FREAD-1447
      //return this.props.history.go(0);

      if (window.location.pathname === "/user") {
        // return this.props.history.push("/flive");
        return this.props.history.replace("/flive");
      }

      return window.location.reload();
      //FREAD-1447
    }
    this.setState({ switchingChild: false });

    //if on /flive or /profile-complete, then on child switch route to student/profile
    let allowedRoutes = ["/profile-complete", "/user", "/student/profile/edit"];
    if (window.location.pathname === "/flive") {
      //if on flive tab and then selects a child
      this.props.history.push("/student/profile");
    } else if (window.location.pathname === "/user") {
      //FREAD-1447
      //return this.props.history.go(0);
      return this.props.history.replace("/flive");

      // return window.location.reload();
      //FREAD-1447
    } else if (
      allowedRoutes.some((route) => route === window.location.pathname)
    ) {
      this.props.history.push("/flive");
    } else if (window.location.pathname === "/assessment") {
      this.props.history.push("/assessment");
    } else {
      //if on read or activities and then child switch, just reload
      //FREAD-1447
      //return this.props.history.go(0);
      return window.location.reload();
      //FREAD-1447
    }
    return window.location.reload();
  };
  logoutUser = async () => {
    const sessiobObj = {
      started_at: this.props.startSessionTime,
      ended_at: new Date(),
    };
    await this.props.callSessionSet(sessiobObj);
    // Mixpanel.track('Logout Event')
    this.logAnalytics("Logout");
    Analytics.onlogOut();
    LocalStorage.destroy.all();
    store.dispatch({ type: "RESET_STATE" });
    this.props.history.push("/onboarding");
  };

  componentDidMount() {
    // let navMenuItems = this.state.navbarLinks3;
    var email = "";

    var referrer = document.referrer;

    email = LocalStorage.fetch.parentData()?.email;

    if (email === "" || email === undefined)
      email = LocalStorage.fetch.parentData()?.contact_no + "@freadom.com";

    if (email === "" || email === undefined) {
      email = LocalStorage.fetch.childData()?.email;
    }

    email = LocalStorage.fetch.parentData()?.email;

    if (email === "" || email === undefined)
      email = LocalStorage.fetch.parentData()?.contact_no + "@freadom.com";

    if (email === "" || email === undefined) {
      email = LocalStorage.fetch.childData()?.email;
    }

    var userData = {
      email: email,
      sub: LocalStorage.fetch.parentData()?.id,
      name: LocalStorage.fetch.parentData()?.name,
      phone: LocalStorage.fetch.parentData()?.contact_no,
      iat: Math.round(new Date().getTime() / 1000), // token issue time
      exp: Math.round(new Date().getTime() / 1000) + 604800, // token expiration time
    };

    let new_value = {
      title: "Community",
      url: [
        "https://freadom.tribe.so/auth/sso?ssoToken=" +
        jwt.sign(userData, privateKey, { algorithm: "HS256" }) +
        "&redirect=/",
      ],
      icon: CommunityIcon,
    };

    if (referrer === "https://freadom.tribe.so/") {
      window.location.assign(new_value.url);
    }

    //FREAD-1940
    //navMenuItems.push(new_value)
    this.setState({
      defaultChildData: LocalStorage.fetch.defaultChild(),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //if(this.props.location.pathname != prevProps.location.pathname)alert('changed');
    if (prevState.switchingChild !== this.state.switchingChild) {
      this.setState({
        defaultChildData: LocalStorage.fetch.defaultChild(),
      });
    }
    if (this.props.levels && this.props.levels.length === 0) {
      this.props.getAllLevels();
    }
  }

  hasKeySetTo(myArray, value) {
    let res = false;
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].hasOwnProperty("key") && myArray[i]["key"] === value)
        res = true;
    }
    return res;
  }
  componentWillReceiveProps(nextProps) {
    // if(nextProps.searchResult!=null && nextProps.searchResult.result &&
    //     nextProps.searchResult.result != null &&
    //     typeof nextProps.searchResult.result.counts !== 'undefined' &&
    // !this.hasKeySetTo(nextProps.searchResult.result.counts,'story') &&
    // this.hasKeySetTo(nextProps.searchResult.result.counts,"activity")){
    //     this.setState({selectedFilter:{
    //         name: "Activities",
    //         selected: true,
    //         value: "activity"
    //     }});
    // }
    // if (nextProps.searchResult && nextProps.searchResult.result && nextProps.searchResult.result.documents) {
    //     // let res = nextProps.searchResult.result.documents.filter(r => r._index =='story' || r._index =='activity')
    //     // if(!res) res=[];
    //     this.setState({ resultList: nextProps.searchResult.result.documents });
    // }
  }

  handleButtonPress() {
    this.cleared = false;
    this.buttonPressTimer = setTimeout(() => {
      this.cleared = true;
      this.setState({
        showSearch: !this.state.showSearch,
        shwContentSrch:
          this.state.shwContentSrch === true
            ? false
            : this.state.shwContentSrch,
        resultList: [],
        filterData: level,
        searchString: null,
      });
    }, 1500);
    if (this.state.showSearch) {
      this.logAnalytics("Search");
    }
  }

  logAnalytics = (to, from = "") => {
    this.scrollToTo();
    if (to !== "Community") {
      from = getSourcePage(from);
      to = getSourcePage(to);
      Analytics.logNavigation({ from: from, to: to });
    } else {
      communityAnalytics();
    }
  };
  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
    //FREAD-1235
    if (!this.cleared) this.props.history.push("/");
    //FREAD-1235
  }
  //FREAD-1235
  handleonMouseLeaveRelease() {
    clearTimeout(this.buttonPressTimer);
  }
  //FREAD-1235
  searchAnything = (evt) => {
    this.setState({ searchString: evt.state.search });
    let searchQuery = "";
    if (evt.state.search) {
      searchQuery = `query=${evt.state.search}`;
    }
    if (this.state.selectedLevel) {
      searchQuery = `${searchQuery}&grade_level=${this.state.selectedLevel}`;
    }
    this.props.getGlobalSearch(searchQuery);
    if (this.state.selectedFilter) {
      //FREAD-1168
      searchQuery = `${searchQuery}&index_type=${this.state.selectedFilter.value}`;
    }
    this.props.getGlobalSearch(searchQuery);
  };

  selectOption = async (value) => {
    if (value && value.length > 0) {
      if (value[0]._index === "activity") {

        this.props.history.push(`/activity/${value[0]._source.id}`);
      } else if (value[0]._index === "story") {

        this.props.history.push(`/story/${value[0]._source.id}`);
      }else if (value[0]._index === "news_fread") {
        this.props.history.push(`/news?id=${value[0]._source.id}`);
      }
    }
  };
  sendPacAnalytics = (event, data) => {
    let payload = {};
    payload[EVENT.ACTIVITY_PACK_NAME] = data.name;
    payload[EVENT.SECTION_ID] = this.props.section; //
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data;
    payload[EVENT.SOURCE_PAGE_ID] = this.props.source;
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.ACTIVITY_PACK_ID] = data?.id;
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    Analytics.logEvents(event, payload);
  };

  logNews = (event, extraPayload) => {
    let payload = {};
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data;
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.SOURCE_PAGE_ID] = this.props.source;
    payload[EVENT.SCHOOL_CODE] = LocalStorage.fetch.defaultChild()?.school_code;
    Analytics.logEvents(event, { ...payload, ...extraPayload });
  };

  handleActivityOpenModal = async (
    id,
    isActivityPack = false,
    gradeId = ""
  ) => {
    let res;
    let result;
    if (isActivityPack) {
      this.state.isActivityPack = true;
      let payload = {
        id: id,
        gradeId: gradeId,
      };
      res = await this.props.getActivityPackDetailsRequest(payload);
      this.sendPacAnalytics(EVENT.VIEW_ACTIVITY_PACK, { id: id, name: "" });
      this.setState({
        activeActivityPackData: res,
        section: EVENT.SOURCE_PAGE_ACTIVITIES,
      });
    } else {
      //   let mixPayload = {
      //     ...this.props.activitiesList?.find((a) => a.id === id),
      //   };
      // Mixpanel.track('Do - Activity Open Click', mixPayload)
      res = await this.props.getActivityDetailRequest(id);
      this.setState({ activeActivityData: res, selectedResult: res });
      result = await this.props.getActivityQuestionsRequest(id);
      this.setState({
        activeActivityQuestionsList: result,
        section: EVENT.SOURCE_INDIVIDUAL_ACTIVITY,
      });
    }
    if (res && Object.keys(res).length > 0) {
      // this.setState({ isActivityModalOpen: true });
    } else {
      alert("No data found");
    }
    this.setState({ isActivityModalOpen: true });
    if (
      this.state.activeActivityPackData &&
      this.state.activeActivityPackData.status === "locked"
    ) {
      // let mixPayload = { ...this.props.lockedActivitiesList?.find(a => a.id === id) }
      this.setState({ section: EVENT.SOURCE_LOCKED_ACTIVITY_PACKS });
      // Mixpanel.track('Do - Locked Activity Pack Open Click', mixPayload)
    } else if (
      this.state.activeActivityPackData &&
      this.state.activeActivityPackData.status === "unlocked"
    ) {
      // let mixPayload = { ...this.props.unlockedActivitiesList?.find(a => a.id === id) }
      // Mixpanel.track('Do - Unlocked Activity Pack Open Click', mixPayload)
      this.setState({ section: EVENT.SOURCE_UNLOCKED_ACTIVITY_PACKS });
    }
  };
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isActivityModalOpen: false,
      openParentProfile: false,
    });
    this.setState({ selectedResult: null });
  };

  selectLevel = (data) => {
    this.setState({ selectedLevel: data });
    let searchQuery = "";
    if (this.state.searchString) {
      searchQuery = `query=${this.state.searchString}`;
      if (data) {
        searchQuery = `${searchQuery}&grade_level=${data}`;
      }
      if (this.state.selectedFilter) {
        searchQuery = `${searchQuery}&index_type=${this.state.selectedFilter.value}`;
      }
      this.props.getGlobalSearch(searchQuery);
    }
  };
  selectFilter = (data) => {
    if (data && this.state.filterData.length > 0) {
      let searchQuery = "";
      const filterOption = this.state.filterData.map((option) => ({
        name: option.name,
        selected: data.name === option.name ? true : false,
        value: option.value,
      }));
      this.setState({ filterData: filterOption, selectedFilter: data });
      if (this.state.searchString) {
        searchQuery = `query=${this.state.searchString}`;
      }
      if (this.state.selectedLevel) {
        searchQuery = `${searchQuery}&grade_level=${this.state.selectedLevel}`;
      }
      if (data) {
        searchQuery = `${searchQuery}&index_type=${data.value}`;
      }
      this.props.getGlobalSearch(searchQuery);
    }
  };
  clearSearch = () => {
    this.setState({ resultList: [] });
  };

  getLink(linkObj) {
    return linkObj.url[0];
  }
  goToCommunity = () => {
    var userData = {
      email: LocalStorage.fetch.parentData()?.email,
      sub: LocalStorage.fetch.parentData()?.id,
      name: LocalStorage.fetch.parentData()?.name,
      phone: LocalStorage.fetch.parentData()?.contact_no,
      iat: Math.round(new Date().getTime() / 1000), // token issue time
      exp: Math.round(new Date().getTime() / 1000) + 604800, // token expiration time
    };
    let url =
      "https://freadom.tribe.so/auth/sso?ssoToken=" +
      jwt.sign(userData, privateKey, { algorithm: "HS256" }) +
      "&redirect=/";
    window.open(url, "_self");
  };
  checkLink = (link) => {
    if (
      link.title === "Community" &&
      !LocalStorage.fetch.parentData()?.is_email_verified
    ) {
      this.setState({ openParentProfile: true, emailCheckFor: link.title });
    }
    this.logAnalytics(link.title);
  };

  navLink = (link, index, external) => {
    return external ? (
      <a
        href={
          LocalStorage.fetch.parentData()?.is_email_verified &&
          link.title === "Community" &&
          link.url[0]
        }
        onClick={() => this.checkLink(link)}
        target="_self"
        className={
          "display-menu" +
          (link.url?.includes(this.props.location.pathname)
            ? " active active-border"
            : "")
        }
      >
        <div
          className={
            "navbar-link" +
            (link.url?.includes(this.props.location.pathname) ? " active" : "")
          }
        >
          <img
            src={link.icon}
            className="navbar-links-icon"
            alt="Navbar icon"
          />
        </div>
        <div className="nav-link-header"> {link.title}</div>
      </a>
    ) : (
      <Link
        to={this.getLink(link)}
        //FREAD-1323
        onClick={() => {
          if (link.title === "News") {
            this.logNews("Tap News", {});
          } else {
            this.logAnalytics(link.title, this.props.location.pathname);
          }
          this.state.shwContentSrch &&
            this.setState({ shwContentSrch: !this.state.shwContentSrch });
        }}
        className={
          "display-menu" +
          (link.url?.includes(this.props.location.pathname)
            ? " active active-border"
            : "")
        }
        key={index}
      >
        <div
          className={
            "navbar-link" +
            (link.url?.includes(this.props.location.pathname) ? " active" : "")
          }
        >
          <img
            src={link.icon}
            className={`navbar-links-icon ${link.title.toLowerCase() === "news"
              ? link.title.toLowerCase()
              : ""
              }`}
            alt="Navbar icon"
          />
        </div>
        <div className="nav-link-header"> {link.title}</div>
      </Link>
    );
  };

  scrollToTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  bookmark = (id) => {
    if (this.props.location.pathname !== "/story") {
      this.props.bookmarkFeed({
        header: this.state.storySection.header,
        id: id,
      });
    } else {
      this.props.bookmarkStory({ id: id });
    }
    this.setState({ activeStoryData: {} });
  };
  render() {
    let { defaultChildData, shwContentSrch } = this.state;
    const childList = LocalStorage.fetch.childData();
    let showSearchInput = this.state.selectedLevel ? false : true;
    const levelList = this.props.levels;
    const { navbarLinks, navbarLinks2, navbarLinks3 } = this.state;
    // let filterOption = [];
    // filterOption = this.state.filterData.map((option) => ({
    //   name: option.name,
    //   selected: option.selected,
    //   value: option.value,
    // }));

    // const demoOptions = this.state.resultList.map((option) => ({
    //   ...option,
    //   label: option._source.name,
    //   value: option.status,
    // }));
    return [
      <div>
        <div
          className={[
            "navbar-container",
            this.props.showNavbar ? "" : "hidden",
          ].join(" ")}
        >
          <span
            className="hamburger-icon"
            onClick={() => this.setState({ navbarOpened: true })}
          >
            &#9776;
          </span>
          <div
            style={{ display: "flex", width: "100%" }}
            className="navbar-left"
          //FREAD-1235
          // onTouchStart={this.handleButtonPress} onTouchEnd={this.handleButtonRelease}
          // onMouseDown={this.handleButtonPress} onMouseUp={this.handleButtonRelease}
          // //onMouseLeave={this.handleButtonRelease}
          // onMouseLeave={this.handleonMouseLeaveRelease}
          >
            {
              //<Link to="/" onClick={this.scrollToTo}>
              <div
                onTouchStart={this.handleButtonPress}
                onTouchEnd={this.handleButtonRelease}
                onMouseDown={this.handleButtonPress}
                onMouseUp={this.handleButtonRelease}
                //onMouseLeave={this.handleButtonRelease}
                onMouseLeave={this.handleonMouseLeaveRelease}
                style={{ display: 'flex', flex: 1, flexDirection: 'row', flexGrow: 1, gap: 8 }}
              >
                <img
                  src={FreadomIcon}
                  onClick={this.scrollToTo}
                  style={{ height: 40, maxWidth: "initial", display: 'flex' }}
                  className="logo"
                  alt="logo"
                />
                <img
                  src={Rotary}
                  onClick={this.scrollToTo}
                  style={{ height: 40, maxWidth: "initial", display: 'flex' }}
                  className="logo"
                  alt="logo"
                />
              </div>
              //</Link>
              //FREAD-1235
            }
            {/* <Link to="/activities">
                <div>Activity</div>
            </Link> */}
            {/* {this.state.showSearch ?
                            <Dropdown overlay={
                                <div className="child-list-container">
                                    {levelList.map(c => (
                                        <div className="child-list-item" onClick={() => this.selectLevel(c)}
                                            key={c.id}>
                                            <div>
                                                {c}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            } trigger={['click']}>
                                <div className="user-pill level-select">
                                    <div
                                        className="user-name">{this.state.selectedLevel ? this.state.selectedLevel : 'Select Level'}</div>
                                    <Icon type="caret-down" />
                                </div>
                            </Dropdown> : <div className="navbar-links-container flex"></div>}
                        {this.state.showSearch ? <div style={{ flex: 1 }}><Select options={demoOptions}
                            showFilter={true}
                            filterData={filterOption}
                            clearable={true}
                            className='search-box'
                            disabled={showSearchInput}
                            selectedFilter={this.state.selectedFilter}
                            onFilterSelect={(values) => this.selectFilter(values)}
                            searchFn={(values) => this.searchAnything(values)}
                            onChange={(values) => this.selectOption(values)}
                            onClearAll={() => this.clearSearch()}
                        /></div> : <div className="navbar-links-container flex"></div>} */}
            <div className="navbar-links-container flex"></div>
            <div className="navbar-links-container flex"></div>
            {/* FREAD-1337 */}
            {!navbarLinks2[0]?.url?.includes(this.props.location?.pathname) && //this.props.location.pathname != '/user'
              this.props.location.pathname !== "/quiz" && (
                <div className="navbar-links-container flex group-link">
                  {/* FREAD-1337 */}
                  {/* <div> */}
                  {/* FREAD-979 */}
                  <a
                    style={{ textDecoration: "none", marginRight: "5px" }}
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        shwContentSrch: !shwContentSrch,
                        showSearch:
                          this.state.showSearch === true
                            ? false
                            : this.state.showSearch,
                      });
                    }}
                    className={
                      "display-menu " +
                      (shwContentSrch ? " active active-border" : "")
                    }
                  >
                    <div
                      className={
                        "navbar-link" + (shwContentSrch ? " active" : "")
                      }
                    >
                      {/* {<Icon type="search" style={{fontSize:'16px'}} />} */}
                      <img
                        src={SearchIcon}
                        className="navbar-links-icon"
                        alt="Search icon"
                      />
                      {
                        //<img src={ SearchIcon } style={{opacity:1,width:'26px'}} className="navbar-links-icon" alt="Navbar icon" />
                      }
                    </div>
                    <div className="nav-link-header">Search</div>
                  </a>
                </div>
              )}
            <div className="navbar-links-container flex group-link">
              {/* FREAD-979 */}
              {navbarLinks.map((link, index) =>
                this.navLink(link, index, false)
              )}
              {/* </div> */}
            </div>
            {/* <div className="navbar-links-container flex group-link">
              {navbarLinks2.map((link, index) =>
                this.navLink(link, index, false)
              )}
            </div> */}
            <div className="navbar-links-container flex group-link-3">
              {navbarLinks3.map(
                (link, index) => this.navLink(link, index, true)
                // <a href={link.url[0]}
                //     target="_self"
                //     className={"display-menu" + (link.url?.includes(this.props.location.pathname) ? " active active-border" : '')}>
                //     <div
                //         className={"navbar-link" + (link.url?.includes(this.props.location.pathname) ? " active" : '')}>
                //         <img src={link.icon} className="navbar-links-icon" alt="Navbar icon" />

                //     </div>
                //     <div> {link.title}</div>
                // </a>
              )}
            </div>
          </div>
          {/* //FREAD-1337 */}
          {!navbarLinks2[0]?.url?.includes(this.props.location?.pathname) && //this.props.location.pathname != '/user'
            this.props.location.pathname !== "/quiz" && (
              <div className="search-mobile">
                {/* //FREAD-1337 */}
                <Icon
                  type="search"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      shwContentSrch: !shwContentSrch,
                      showSearch:
                        this.state.showSearch === true
                          ? false
                          : this.state.showSearch,
                    });
                  }}
                />
              </div>
            )}
          {defaultChildData &&
            Object.keys(defaultChildData)?.length > 0 &&
            !this.props?.hideForCMFluency && (
              <div className="user-data">
                <Dropdown
                  overlay={
                    <div className="child-list-container">
                      {childList &&
                        childList.list.map((c) => (
                          <div
                            className="child-list-item"
                            style={{
                              border:
                                c.id === defaultChildData.id
                                  ? "1px solid #0033A0"
                                  : "none",
                              background:
                                c.id === defaultChildData.id
                                  ? "#91d8f7"
                                  : "none",
                            }}
                            onClick={() => this.handleSwitchChild(c.id)}
                            key={c.id}
                          >
                            <div className="child-name">
                              {c.image && c.image!=='null' ? (
                                <img
                                  src={c.image}
                                  alt="user"
                                  className="child-image"
                                />
                              ) : (
                                <Avatar className="child-image">
                                  {c.name[0]}
                                </Avatar>
                              )}
                              <span id={c.id} className="name-trunc">
                                {c.name}{" "}
                                {c.grade && c.grade.name ? (
                                  <span className="name-trunc grade-text">
                                    {c.grade.name}
                                  </span>
                                ) : null}
                              </span>
                            </div>
                            {/* <img src={c.id === defaultChildData.id ? CircleCheck : CircleUnCheck}
                                             alt="check"/> */}
                          </div>
                        ))}
                      <hr className="no-margin"></hr>
                      <div
                        className="child-list-item"
                        onClick={() =>
                          this.setState({
                            openParentProfile: true,
                            emailCheckFor: "profileSetting",
                          })
                        }
                      >
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div className="icon-container">
                            <img
                              src={SettingsIcon}
                              alt="user"
                              className="menu-image"
                            />
                          </div>
                          Parent Profile
                        </div>
                      </div>
                      <div
                        className="child-list-item"
                        onClick={this.logoutUser}
                      >
                        <div style={{ display: "flex" }}>
                          {" "}
                          <div className="icon-container">
                            <img
                              src={LogoutIcon}
                              alt="user"
                              className="menu-image"
                            />
                          </div>
                          Logout
                        </div>
                      </div>
                    </div>
                  }
                  trigger={["click"]}
                >
                  <div className="user-pill">
                    {/* Commented just in case we need to show parent data. */}
                    {/* {parentData.image
                ? <img className="display-picture" src={parentData.image} alt="user" />
                : <Avatar className="display-picture">{parentData.name[0]}</Avatar>
              }
              <div>
                <div className="user-name">{parentData.name}</div>
                <div className="parent-text">Parent</div>
              </div> */}
                    {defaultChildData.image && defaultChildData?.image!=='null' ? (
                      <img
                        className="display-picture"
                        src={defaultChildData.image}
                        alt="user"
                      />
                    ) : (
                      <Avatar className="display-picture">
                        {defaultChildData.name && defaultChildData.name[0]}
                      </Avatar>
                    )}
                    {/* <div className="user-name">{defaultChildData.name}</div> */}
                    {/* <Icon type="caret-down"/> */}
                  </div>
                </Dropdown>
                {/* <Popconfirm
                            placement="bottom"
                            title={"Are you sure you want to logout ?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={this.logoutUser}
                        >
                            <div className="swap-icon">
                                <Icon type="logout" style={{color: "#fff"}}/>
                            </div>
                        </Popconfirm> */}
              </div>
            )}

          <div
            className="navbar-overlay"
            style={{ height: this.state.navbarOpened ? "100%" : "0" }}
          >
            <p
              className="close-btn"
              onClick={() => this.setState({ navbarOpened: false })}
            >
              &times;
            </p>
            <div className="overlay-content">
              <div className="navbar-links-container flex">
                {navbarLinks.map((link, index) => (
                  <Link
                    to={link.url[0]}
                    onClick={() => {
                      this.logAnalytics(
                        link.title,
                        this.props.location.pathname
                      );
                    }}
                    key={index}
                  >
                    <div className="navbar-link">{link.title}</div>
                  </Link>
                ))}
                {navbarLinks2.map((link, index) => (
                  <Link
                    to={link.url[0]}
                    onClick={() => {
                      this.logAnalytics(
                        link.title,
                        this.props.location.pathname
                      );
                    }}
                    key={index}
                  >
                    <div className="navbar-link">{link.title}</div>
                  </Link>
                ))}
                {navbarLinks3.map((link, index) => (
                  <a
                    href={
                      LocalStorage.fetch.parentData()?.is_email_verified
                        ? link.url[0]
                        : "#"
                    }
                    onClick={() => this.checkLink(link)}
                    target="_self"
                  >
                    <div className="navbar-link">{link.title}</div>
                  </a>
                ))}
                {/* <a>
                                    <div className="navbar-link" onClick={this.logoutUser}>
                                        Logout
                                    </div>
                                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="Srch_main"
          style={{
            display: this.state.showSearch ? "flex" : "none",
            alignItems: "center",
          }}
        >
          {/* <a
            href="/"
            className="search-anchors"
            style={{ width: "2%" }}
            onClick={(e) => e.preventDefault()}
          ></a> */}
          {this.state.showSearch ? (
            <Dropdown
              overlay={
                <div className="child-list-container">
                  {levelList.map((c) => (
                    <div
                      className="child-list-item"
                      onClick={() => this.selectLevel(c)}
                      key={c.id}
                    >
                      <div>{c}</div>
                    </div>
                  ))}
                </div>
              }
              trigger={["click"]}
            >
              <div className="user-pill level-select">
                <div className="user-name">
                  {this.state.selectedLevel
                    ? this.state.selectedLevel
                    : "Levels"}
                </div>
                <Icon type="caret-down" />
              </div>
            </Dropdown>
          ) : (
            <div className="navbar-links-container flex"></div>
          )}
          {/* <a
            href="/"
            className="search-anchors"
            onClick={(e) => e.preventDefault()}
          ></a> */}
          {this.state.showSearch ? (
            <div style={{ flex: 1 }}>
              {/* <Select options={demoOptions}
                            showFilter={true}
                            filterData={filterOption}
                            clearable={true}
                            closeOnSelect={true}
                            className='search-box'
                            placeholder="Search"
                            disabled={showSearchInput}
                            selectedFilter={this.state.selectedFilter}
                            onFilterSelect={(values) => this.selectFilter(values)}
                            searchFn={(values) => this.searchAnything(values)}
                            onChange={(values) => this.selectOption(values)}
                            onClearAll={() => this.clearSearch()}
                        /> */}
              <TeacherSearch
                selectOption={this.selectOption}
                showSearchInput={showSearchInput}
                gradeLevel={this.state.selectedLevel}
                shwContentSrch={this.state.showSearch}
              />
            </div>
          ) : (
            <div className="navbar-links-container flex"></div>
          )}
        </div>
        <ContentSrch
          selectOption={this.selectOption}
          shwContentSearch={shwContentSrch}
        />
        {/* {this.state.showSearch ?
                    <div className="sub-header">
                        <Dropdown overlay={
                            <div className="child-list-container">
                                {levelList.map(c => (
                                    <div className="child-list-item" onClick={() => this.selectLevel(c)}
                                         key={c.id}>
                                        <div>
                                            {c}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            } trigger={['click']}>
                            <div className="user-pill level-select">
                                <div className="user-name">{this.state.selectedLevel?this.state.selectedLevel : 'Select Level'}</div>
                                <Icon type="caret-down"/>
                            </div>
                        </Dropdown>
                        <Select options={demoOptions}
                            showFilter={true}
                            filterData={filterOption}
                            clearable={true}
                            className='search-box'
                            disabled={showSearchInput}
                            selectedFilter={this.state.selectedFilter}
                            onFilterSelect={(values) =>this.selectFilter(values)}
                            searchFn={(values) =>this.searchAnything(values)}
                            onChange={(values) =>this.selectOption(values)}
                            onClearAll={() =>this.clearSearch()}
                        />

                    </div>
                        :null}  */}
      </div>,
      <Modal
        title="Book Summary"
        centered
        // width="60vw"
        className="story-modal"
        // Add check when activeStory is set, then set the modal to open
        visible={
          this.state.isModalOpen && Boolean(this.state.selectedResult.id)
        }
        onCancel={() => this.closeModal()}
        footer={null}
        key={1}
        destroyOnClose
      >
        <BookSummary
          bookmark={this.bookmark}
          source={EVENT.SOURCE_FEED_PAGE}
          data={this.state.selectedResult}
          questions={this.state.activeStoryQuestion}
          openSuccessModal={this.openSuccessModal}
          close={this.closeModal}
        />
      </Modal>,
      <Modal
        title="Parent Profile"
        centered
        // width="60vw"
        // className="story-modal"
        // Add check when activeStory is set, then set the modal to open
        visible={this.state.openParentProfile}
        onCancel={() => this.closeModal()}
        footer={null}
        key={1}
        destroyOnClose
      >
        <ParentProfile
          fromLink={this.state.emailCheckFor}
          close={this.closeModal}
          goToCommunity={this.goToCommunity}
        />
      </Modal>,
      <Modal
        title="Activity Summary"
        centered
        className="activity-modal"
        visible={this.state.isActivityModalOpen}
        onCancel={() => this.closeModal()}
        footer={null}
        key={1}
        destroyOnClose
      >
        <ActivityModal
          section={this.state.section}
          source={EVENT.SOURCE_FEED_PAGE}
          data={
            this.state.isActivityPack
              ? this.state.activeActivityPackData
              : this.state.isReadingChallenge
                ? this.state.activeReadingChallengeData
                : this.state.activeActivityData
          }
          questions={
            this.state.isActivityPack
              ? []
              : this.state.isReadingChallenge
                ? this.state.activeReadingChallengeQuestionsList
                : this.state.activeActivityQuestionsList
          }
          openSuccessModal={(data) => this.openSuccessModal(data)}
          close={(id, isActivityPack, gradeId) =>
            this.closeModal(id, isActivityPack, gradeId)
          }
          isActivityPack={this.state.isActivityPack}
          isReadingChallenge={this.state.isReadingChallenge}
        />
      </Modal>,
    ];
  }
}

export default connect(
  ({ user, teacher, story, activity, appConfig, student, home }) => ({
    ...user,
    ...appConfig,
    ...teacher,
    ...story,
    ...activity,
    ...home,
    student,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...userActions,
        ...homeActions,
        ...teacherActions,
        ...storyActions,
        ...activityActions,
      },
      dispatch
    )
)(withRouter(Navbar));
